import { Injectable } from '@angular/core';
import { Network } from '@capacitor/network';
import { LoadingController } from '@ionic/angular/standalone';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { Events } from '../events/events.service';
import { OfflineService } from '../offline/offline.service';
import { SyncService } from '../sync/sync.service';
import { NetworkSpeedService } from './network-speed.service';

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  public static isConnected: boolean;

  constructor(
    private events: Events,
    private syncService: SyncService,
    private offlineService: OfflineService,
    private networkSpeedService: NetworkSpeedService,
    private loadingCtrl: LoadingController,
    private translateService: TranslateService
  ) {}

  async launchNetworkListener() {
    await this.setNetworkStatus();
    Network.addListener('networkStatusChange', async () => {
      const connected = await this.getNetworkStatus();
      if (connected !== NetworkService.isConnected) {
        await this.setNetworkStatus();
        this.events.publish('network-changed', { isConnected: NetworkService.isConnected });
      }
    });
  }

  async getNetworkStatus(): Promise<boolean> {
    const status = await Network.getStatus();
    return status.connected && (status.connectionType === 'cellular' || status.connectionType === 'wifi');
  }

  private async setNetworkStatus() {
    NetworkService.isConnected = await this.getNetworkStatus();
    const account = AuthService.currentUser;
    if (account && NetworkService.isConnected && !OfflineService.isOffline) {
      const message = await firstValueFrom(this.translateService.get('TESTING_NETWORK_SPEED'))
      const loading = await this.loadingCtrl.create({
        message,
        animated: true,
        backdropDismiss: false,
      });
      await loading.present();
      const isSlow = await this.networkSpeedService.isNetworkSlow();
      if (isSlow) {
        await loading.dismiss();
        await this.networkSpeedService.showSlowNetworkAlert();
        await this.offlineService.setMode(true);
      } else {
        loading.dismiss();
        await this.syncService.sync();
      }
    }
  }
}
