import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { Events } from '../events/events.service';
import { NetworkSpeedService } from '../network/network-speed.service';
import { NetworkService } from '../network/network.service';
import { SyncService } from '../sync/sync.service';

@Injectable({
  providedIn: 'root',
})
export class OfflineService {
  public static isOffline: boolean;

  constructor(
    private events: Events,
    private syncService: SyncService,
    private networkSpeedService: NetworkSpeedService,
    private loadingCtrl: LoadingController,
    private translateService: TranslateService
  ) {}

  async switchMode() {
    OfflineService.isOffline = !OfflineService.isOffline;
    await Preferences.set({ key: 'mode', value: OfflineService.isOffline ? 'offline' : 'online' });
    const isOffline = OfflineService.isOffline;
    if (!isOffline && NetworkService.isConnected) {
      const message = await firstValueFrom(this.translateService.get('TESTING_NETWORK_SPEED'))
      const loading = await this.loadingCtrl.create({
        message,
        animated: true,
        backdropDismiss: false,
      });
      await loading.present();
      const isSlow = await this.networkSpeedService.isNetworkSlow();
      if (isSlow) {
        loading.dismiss();
        await this.networkSpeedService.showSlowNetworkAlert();
        await this.setMode(true);
      } else {
        loading.dismiss();
        await this.syncService.sync();
      }
    }
    this.events.publish('switch-mode', { isOffline });
  }

  async setMode(isOffline: boolean) {
    OfflineService.isOffline = isOffline;
    await Preferences.set({ key: 'mode', value: isOffline ? 'offline' : 'online' });
  }

  async getMode(): Promise<boolean> {
    const result = await Preferences.get({ key: 'mode' });
    return result.value === 'offline' ? true : false;
  }

  async setupMode() {
    const result = await Preferences.get({ key: 'mode' });
    OfflineService.isOffline = result.value === 'offline' ? true : false;
  }
}
