export const DEBOUCE_TIME = 800;
export const LOCAL_CHANGE = 'localChange';

export function isNotNull(value: any) {
  return value !== null && value !== undefined;
}

export function isNull(value: any) {
  return value === null || value === undefined;
}
