import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular/standalone';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

enum NetworkSpeed {
  VERY_FAST = 'Very Fast',
  FAST = 'Fast',
  MODERATE = 'Moderate',
  SLOW = 'Slow',
  VERY_SLOW = 'Very Slow',
}

@Injectable({
  providedIn: 'root',
})
export class NetworkSpeedService {
  constructor(
    private httpClient: HttpClient,
    private alertCtrl: AlertController,
    private translateService: TranslateService,
  ) {}

  private getSpeedCategory(speedMbps: number): NetworkSpeed {
    if (speedMbps > 25) {
      return NetworkSpeed.VERY_FAST;
    } else if (speedMbps > 10) {
      return NetworkSpeed.FAST;
    } else if (speedMbps > 5) {
      return NetworkSpeed.MODERATE;
    } else if (speedMbps > 1) {
      return NetworkSpeed.SLOW;
    } else {
      return NetworkSpeed.VERY_SLOW;
    }
  }

  async isNetworkSlow(): Promise<boolean> {
    const startTime = performance.now();
    try {
      // Maybe add a timeout
      const response = await firstValueFrom(
        this.httpClient.get(`${environment.apiUrl}/public/ping`, { responseType: 'blob', observe: 'response' }),
      );
      if (!response.body) return true;
      const endTime = performance.now();
      const duration = (endTime - startTime) / 1000; // Time in seconds
      const fileSize = response.body.size; // File size in bytes
      // Convert bytes per second to megabits per second
      const speedMbps = (fileSize * 8) / (duration * 1024 * 1024);
      const speedCategory = this.getSpeedCategory(speedMbps);
      return speedCategory === NetworkSpeed.SLOW || speedCategory === NetworkSpeed.VERY_SLOW ? true : false;
    } catch (error) {
      console.error('Download failed', error);
      return true;
    }
  }

  async showSlowNetworkAlert() {
    const values = await firstValueFrom(
      this.translateService.get(['SLOW_NETWORK_DETECTED', 'SLOW_NETWORK_DETECTED_HEADER']),
    );
    const alert = await this.alertCtrl.create({
      header: values.SLOW_NETWORK_DETECTED_HEADER,
      message: values.SLOW_NETWORK_DETECTED,
      buttons: [
        {
          text: 'Ok',
          role: 'destructive',
        },
      ],
      backdropDismiss: false,
    });
    await alert.present();
  }
}
